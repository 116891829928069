const faqs = [
  {
    question: "1. Requisitos para viajar a Francia:",
    answer: (
      <div>
        <p>- Pasaporte válido: Asegúrate de tener un pasaporte válido que no expire durante tu estancia en Francia. Se recomienda que tenga una validez mínima de seis meses a partir de la fecha de ingreso. <br/> <br/>
        - Visa (si corresponde): Los ciudadanos argentinos no necesitan visa para ingresar a Francia como turistas si su estancia no supera los 90 días en un período de 180 días. Sin embargo, si planeas quedarte por más tiempo, trabajar o estudiar, es posible que necesites obtener una visa correspondiente antes de viajar. Verificá los requisitos de visa específicos según tu situación.<br/> <br/>
       - Seguro de viaje: Si bien no es un requisito obligatorio, se recomienda encarecidamente contar con un seguro de viaje que brinde cobertura médica y de repatriación en caso de enfermedad o accidente durante tu estancia en Francia. Además, algunos países europeos exigen un seguro de viaje como requisito para ingresar.
<br/> <br/>
       - Prueba de solvencia económica: Es posible que debas demostrar que cuentas con los medios financieros suficientes para cubrir tus gastos durante tu estancia en Francia. Esto puede incluir presentar extractos bancarios, tarjetas de crédito o cualquier otro documento que respalde tu capacidad económica.

</p>
      </div>
    ),
  },
  {
    question: "2. ¿Qué vacunas son necesarias para viajar a Francia?",
    answer: (
      <div>
      <p>Actualmente, no se requieren vacunas específicas para ingresar a Francia desde Argentina. Sin embargo, es recomendable mantener al día las vacunas recomendadas y consultar las recomendaciones de salud actualizadas antes de viajar. Puedes disfrutar de tu aventura francesa sin preocupaciones adicionales relacionadas con las medidas sanitarias.
</p>
    </div>
    ),
  },
  {
    question: "3. ¿Si viajo a Francia puedo salir a otros países europeos?",
    answer: (
      <div>
      <p>Una vez que ingreses al primer país de la zona Schengen, como lo es Francia, podrás moverte libremente por los otros 25 países sin necesidad de realizar controles migratorios adicionales durante un máximo de 90 días.
</p>
    </div>
    ),
  },


];

export default faqs;
